import './assets/css/App.css';
import { useEffect, useRef, useState } from "react";
import Timer from "./components/Timer";
import ResultPage from "./components/ResultPage";
import ConfettiExplosion from 'react-confetti-explosion';
import bg1 from './assets/images/bg-1.jpg';

function App() {
    const [timerRemaining, setTimerRemaining] = useState(10.0);
    const [running, setRunning] = useState(false);
    const [clickCount, setClickCount] = useState(0);
    const [finished, setFinished] = useState(false);
    const [isExploding, setIsExploding] = useState(false);
    const [highScore, setHighScore] = useState(0);
    const [bgIndex, setBgIndex] = useState(0);
    const [timeStart, setTimeStart] = useState(0);
    const [bg, setBg] = useState(bg1);

    const colors = ['#ff0000', '#ff7f00', '#ffff00', '#00ff00', '#0000ff', '#4b0082', '#9400d3']; // Dodajte više boja po potrebi

    const lastInterval = useRef();

    useEffect(() => {
        if (lastInterval.current) {
            clearInterval(lastInterval.current);
        }
        if (running) {
            lastInterval.current = setInterval(() => {
                setTimerRemaining(timerRemaining => {
                    if (timerRemaining <= 0.1) {
                        setRunning(false);
                        setFinished(true);
                        return 0;
                    }
                    return (timeStart - +new Date()) / 1000;
                });
            }, 100);
        }
        // eslint-disable-next-line
    }, [running]);

    useEffect(() => {
        let highScore_ = 0;
        // Čitanje vrijednosti kolačića
        const cookies = document.cookie.split(";");
        for (const cookie of cookies) {
            const [name, value] = cookie.trim().split("=");
            if (name === "score-v2") {
                highScore_ = parseInt(value);
                break;
            }
        }

        if (finished === true && highScore_ < clickCount) {
            document.cookie = `score-v2=${clickCount}; expires=Thu, 01 Jan 2024 00:00:00 UTC; path=/`;
            setIsExploding(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finished]);

    useEffect(() => {
        if (finished === true) {
            // Čitanje vrijednosti kolačića
            const cookies = document.cookie.split(";");
            for (const cookie of cookies) {
                const [name, value] = cookie.trim().split("=");
                if (name === "score-v2") {
                    setHighScore(parseInt(value));
                    break;
                }
            }
        }
    }, [finished]);

    function getNextColorIndex(currentIndex) {
        return (currentIndex + 1) % colors.length;
    }

    return (
        <div className="App" onClick={() => {
            if (typeof window.buttonClicked !== 'undefined' && window.buttonClicked === true) {
                window.buttonClicked = false;
            } else {
                setTimeStart(+new Date() + 10000);
                setRunning(true)
            }

            if (running) {
                setClickCount(clickCount + 1);
                setBgIndex(prevIndex => getNextColorIndex(prevIndex));
            }
        }}>
            <header className={"App-header"} style={{ backgroundColor: colors[bgIndex] }}>
                <div className={"background"} style={{
                    background: 'url(' + bg + ')',
                }}></div>
                <div className={"App-content " + (finished ? 'center' : '')}>
                    {!finished && <Timer timerRemaining={timerRemaining} />}
                    {!finished && <span>{clickCount}</span>}
                    {isExploding && <ConfettiExplosion force={0.6} particleCount={175} />}
                    {finished && <ResultPage
                        clickCount={clickCount}
                        setTimerRemaining={setTimerRemaining}
                        setClickCount={setClickCount}
                        setFinished={setFinished}
                        setRunning={setRunning}
                        highScore={highScore}
                        setIsExploding={setIsExploding}
                        setBg={setBg}
                    />}
                </div>
            </header>
        </div>
    );
}

export default App;
