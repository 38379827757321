import bg1 from "../assets/images/bg-1.jpg";
import bg2 from "../assets/images/bg-2.jpg";
import bg3 from "../assets/images/bg-3.jpg";

function resultPage({
                        clickCount,
                        setTimerRemaining,
                        setClickCount,
                        setFinished,
                        setRunning,
                        highScore,
                        setIsExploding,
                        setBg,
                    }) {
    return (<div className={"result-container"}>
        <div className={"result"}>
            {clickCount}
            {highScore === clickCount && <span
                dangerouslySetInnerHTML={{__html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/></svg>`}}></span>}
        </div>
        {highScore > clickCount && <div className={"skor"}>{highScore}<span
            dangerouslySetInnerHTML={{__html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/></svg>`}}></span>
        </div>}
        <button onClick={() => {
            setTimerRemaining(10.0)
            setClickCount(0)
            setFinished(false)
            setRunning(false)
            setIsExploding(false)
            window.buttonClicked = true;

            let bgIndex = 0;
            while (bgIndex > 3 || bgIndex < 1) {
                bgIndex = Math.round(Math.random() * 10);
            }

            switch (bgIndex) {
                case 1:
                    setBg(bg1);
                    break;
                case 2:
                    setBg(bg2);
                    break;
                case 3:
                    setBg(bg3);
                    break;
                default:
                    setBg(bg1);
                    break;
            }

        }} className={"try-again"}
                dangerouslySetInnerHTML={{__html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z"/></svg>`}}>
        </button>
    </div>)
}

export default resultPage;
